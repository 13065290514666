header {
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: left;
}

.logo h2 {
  margin: 0;
  color: #157dc8;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 14px;
}

.logo h2 span {
  font-size: 19px;
}

.header-bar {
  padding: 0 25px;
  text-align: right;
  background-color: #4b525a;
}

.header-bar ul,
.navbar ul {
  padding: 0;
  margin: 0;
}

.header-bar ul li {
  list-style-type: none;
  display: inline-block;
  margin-left: 30px;
}

.header-bar ul li a {
  font-size: 16px;
  padding: 15px 0;
  color: #ffffff;
  font-weight: 300;
  display: inline-block;
  text-decoration: none;
  transition: all 0.4s ease;
}

.header-bar ul li a:hover {
  color: #157dc8;
}

.header-select .MuiOutlinedInput-root {
  background: #fafafa;
  color: black;
  padding: 2px 10px !important;
  border-radius: 25px;
  font-size: 14px;
}

.header-select .MuiSelect-select {
  padding: 0 !important;
}

.header-select .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0;
}

.header-select svg {
  display: none;
}

.navbar {
  padding: 0 25px;
  text-align: right;
  background-color: #157dc8;
}

.navbar ul li {
  list-style-type: none;
  display: inline-block;
  margin-left: 30px;
}

.navbar ul li a {
  font-size: 16px;
  padding: 20px 0;
  color: #ffffff;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
  transition: all 0.4s ease;
  border-bottom: 2px solid transparent;
}

.navbar ul li a.active {
  color: white;
  border-bottom: 2px solid white;
}

.navbar ul li:first-child {
  margin-left: 5px;
}
